<template>
  <div class="apply_page">
    <div class="senate_top">
      <div class="my_banner">
        <img src="../../assets/pc_img/jci_banner.png" alt="">
      </div>
      <div class="banner_content">
        <h3>JCI SENATE</h3>
        <p>More than 50 years ago, the JCI Senate was established to recognize the outstanding achievements and service
          of JCI members worldwide.</p>
      </div>
      <topNavBar></topNavBar>
    </div>
    <div class="back_content">
      <div class="content">
        <div class="back_btn" @click="$router.back()"><i>></i><span>Back</span></div>
        <h3>Give a JCI Senatorship</h3>
      </div>
    </div>
    <div class="senatorship">
      <div class="content">
        <div class="item">
          <div class="img_box"><img src="../../assets/pc_img/senatorship.png" alt=""></div>
          <span>GIVE A SENATORSHIP</span>
          <p>If there is an exceptional JCI member in your Local Organization, honor them with a JCI Senatorship. JCI
            Senatorships are a great way to show outstanding members their value to the organization. Complete the
            simple application process to recognize a JCI Senator in your Local Organization today. The JCI Senate is an
            honor that lasts a lifetime.</p>
          <p>Upon approval, JCI Senators receive a senate number, a personalized
            certificate, a membership card, a JCI Senate pin and additional programming options at JCI Events.</p>
        </div>
        <div class="item">
          <div class="img_box"><img src="../../assets/pc_img/eligibility.png" alt=""></div>
          <span>ELIGIBILITY</span>
          <p>JCI Senatorships are available for both current and past members. Any current JCI member who has served the
            JCI organization, at any level, for at least three years may be eligible for a JCI Senatorship. Past JCI
            members who served the JCI organization, at any level, for at least three years are also eligible with
            written approval from their JCI Local and National Organizations.</p>
        </div>
        <div class="item">
          <div class="img_box"><img src="../../assets/pc_img/process.png" alt=""></div>
          <span>APPLICATION PROCESS</span>
          <p>The application can only be approved by the Local President, State President (if applicable) and National
            President of which the applicant is a member. The President can only accept applications that meet the
            required approval as listed above. No other approval will be accepted. A contribution of US $300 must be
            made by the sponsoring JCI Local or National Organization prior to or upon request of the approval by the
            JCI President.</p>
        </div>
        <div class="sen_btn">
          <div class="btn_com" @click="exportBtn">APPLY FOR JCI SENATE</div>
        </div>
      </div>
    </div>
    <pageFooter />
  </div>
</template>

<script>
  import pageFooter from "./components/pageFooter";
  import topNavBar from "./components/topNavBar";

  export default {
    components: {
      topNavBar,
      pageFooter
    },
    methods: {
      exportBtn() {
        let link = document.createElement('a');
        link.setAttribute("download", "");
        link.href = "./SenateAppEN.doc"; // 你本地资源文件的存放地址
        link.click();
      }
    },
  };
</script>

<style scoped lang='less'>
  .apply_page {
    .senate_top {
      position: relative;
    }

    .my_banner {
      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .banner_content {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      text-align: center;
      width: 680px;
      transform: translateX(-50%) translateY(-50%);
      font-size: 20px;

      h3 {
        font-size: 60px;
        margin-bottom: 60px;
      }
    }

    .back_content {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        width: 1200px;
        position: relative;
      }

      .back_btn {
        width: 100px;
        height: 45px;
        background: #f2f3f8;
        border-radius: 4px;
        color: #0d0f30;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
          margin-right: 20px;
        }
      }

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .senatorship {
      background-color: #F4F4F4;
      padding: 28px 0 80px;

      .content {
        width: 1200px;
        margin: 0 auto;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #0D0F30;
        background-color: #fff;
        height: 350px;
        margin-bottom: 30px;
        padding: 0 100px;

        p {
          font-size: 14px;
        }

      }

      span {
        font-size: 18px;
        margin: 30px 0 15px;
      }
    }

    .sen_btn {
      display: flex;
      justify-content: center;
    }

    .btn_com {
      height: 50px;
      line-height: 50px;
      background: #4266b0;
      border-radius: 25px;
      color: #fff;
      padding: 0 26px;
      margin-right: 20px;
      cursor: pointer;
      font-size: 16px;
    }
  }
</style>